import React from 'react';

import { BLOCK_TYPE } from '@components/blocks';

import { Container } from './embedBlock.styles';

export interface EmbedBlockProps {
  content: string;
}

export const EmbedBlock = ({ content }: EmbedBlockProps) => (
  <Container dangerouslySetInnerHTML={{ __html: content }} data-testid={BLOCK_TYPE.EMBED_HTML} />
);
