import React, { ReactNode } from 'react';
import { always, cond, equals, ifElse, isEmpty, pipe, prop, test } from 'ramda';
import { useInView } from 'react-intersection-observer';
import { useRouter } from 'next/router';

import { Block } from '@definitions/common.types';
import {
  AboutUs,
  ArchivedEditorialBlock,
  BLOCK_TYPE,
  CenteredText,
  FaqLegalBlock,
  Heading,
  ImageBlock,
  ImageWithText,
  NewsletterBlock,
  OrganizedLegalBlock,
  Paragraph,
  ParagraphWithTitle,
  ProductsBlock,
  ProductsWithBasicTitle,
  ProductsWithTitle,
  Quote,
  SimpleLegalBlock,
  VideoBlock,
} from '@components/blocks';
import { EmbedBlock } from '@components/blocks/embedBlock';
import { DEFAULT_BACKGROUND_COLOR, DEFAULT_TEXT_COLOR } from '@utils/constants';
import { MapComponent } from '@components/map';
import { Columns } from '@components/blocks/columns';
import { TwoPieceEditorial } from '@components/blocks/twoPieceEditorial';
import { ShoppableImage } from '@components/blocks/shoppableImage/shoppableImage.component';
import { AdvertisementBlock } from '@components/blocks/advertisementBlock';

import { Container } from './contentBody.styles';

export interface ContentBodyProps {
  children?: ReactNode;
  body: Block[];
  hasSpacingTop?: boolean;
  hasSpacingBottom?: boolean;
  backgroundColor?: string;
  textColor?: string;
  shoppable?: boolean;
  accessToken?: string;
  optimise?: boolean;
}

export const ContentBody = ({
  body,
  children = null,
  hasSpacingTop = true,
  hasSpacingBottom = true,
  backgroundColor = DEFAULT_BACKGROUND_COLOR,
  textColor = DEFAULT_TEXT_COLOR,
  shoppable,
  accessToken,
  optimise = true,
}: ContentBodyProps) => {
  const router = useRouter();
  const pageTag = `${router?.asPath?.split('/')[1]}/${router?.asPath?.split('/')[2]}`;

  const getBlock = (block: Block) =>
    cond([
      [test(/^h[1-6]$/), always(<Heading {...block} />)],
      [equals(BLOCK_TYPE.SHOPPABLE_IMAGE), always(<ShoppableImage content={block.value} />)],
      [equals(BLOCK_TYPE.ADVERTISEMENT), always(<AdvertisementBlock value={block.value} />)],
      [equals(BLOCK_TYPE.COLUMNS), always(<Columns content={block.value} />)],
      [equals(BLOCK_TYPE.TWO_PIECE_EDITORIAL), always(<TwoPieceEditorial content={block.value} />)],
      [equals(BLOCK_TYPE.EMBED_HTML), always(<EmbedBlock content={block.value} />)],
      [equals(BLOCK_TYPE.PARAGRAPH), always(<Paragraph content={block.value} />)],
      [
        equals(BLOCK_TYPE.PARAGRAPH_WITH_TITLE),
        always(<ParagraphWithTitle {...block.value} isContinuReadingClicked={true} />),
      ],
      [equals(BLOCK_TYPE.IMAGE_BLOCK), always(<ImageBlock {...block.value} />)],
      [equals(BLOCK_TYPE.VIDEO_BLOCK), always(<VideoBlock {...block.value} />)],
      [equals(BLOCK_TYPE.QUOTE), always(<Quote content={block.value} />)],
      [equals(BLOCK_TYPE.MAP), always(<MapComponent {...block.value} />)],
      [
        equals(BLOCK_TYPE.PRODUCTS),
        always(
          <ProductsBlock
            artworks={block.value}
            textColor={textColor}
            backgroundColor={backgroundColor}
            shoppable={shoppable}
            accessToken={accessToken}
          />
        ),
      ],
      [
        equals(BLOCK_TYPE.PRODUCTS_WITH_TITLE),
        always(
          <ProductsWithTitle
            {...block.value}
            textColor={textColor}
            backgroundColor={backgroundColor}
            shoppable={shoppable}
            accessToken={accessToken}
          />
        ),
      ],
      [
        equals(BLOCK_TYPE.HORIZONTAL_PRODUCTS_WITH_TITLE),
        always(
          <ProductsWithTitle
            {...block.value}
            textColor={textColor}
            backgroundColor={backgroundColor}
            shoppable={shoppable}
            accessToken={accessToken}
          />
        ),
      ],
      [
        equals(BLOCK_TYPE.PRODUCTS_WITH_BASIC_TITLE),
        always(
          <ProductsWithBasicTitle
            {...block.value}
            textColor={textColor}
            backgroundColor={backgroundColor}
            shoppable={shoppable}
            accessToken={accessToken}
          />
        ),
      ],
      [equals(BLOCK_TYPE.IMAGE_WITH_TEXT), always(<ImageWithText {...block.value} textColor={textColor} />)],
      [equals(BLOCK_TYPE.ABOUT_US), always(<AboutUs {...block.value} />)],
      [equals(BLOCK_TYPE.CENTERED_TEXT), always(<CenteredText content={block.value} />)],
      [equals(BLOCK_TYPE.SIMPLE_LEGAL_BLOCK), always(<SimpleLegalBlock {...block.value} />)],
      [equals(BLOCK_TYPE.FAQ_LEGAL_BLOCK), always(<FaqLegalBlock {...block.value} />)],
      [equals(BLOCK_TYPE.ORGANIZED_LEGAL_BLOCK), always(<OrganizedLegalBlock {...block.value} />)],
      [equals(BLOCK_TYPE.ARCHIVED_EDITORIAL_BLOCK), always(<ArchivedEditorialBlock {...block.value} />)],
      [
        equals(BLOCK_TYPE.NEWSLETTER_BLOCK),
        always(
          <NewsletterBlock
            {...block.value}
            textColor={textColor}
            backgroundColor={backgroundColor}
            tag={pageTag || ''}
          />
        ),
      ],
      // @ts-ignore
    ])(block.type);

  const renderBlock = ifElse(pipe(prop('value'), isEmpty), always(null), getBlock);

  const BlockElement = ({ block, id }: { block: Block; id: string }) => {
    const [ref, inView] = useInView({ triggerOnce: true });
    const excludedBlockTypes = [
      BLOCK_TYPE.MAP,
      BLOCK_TYPE.EMBED_HTML,
      BLOCK_TYPE.IMAGE_BLOCK,
      BLOCK_TYPE.VIDEO_BLOCK,
      BLOCK_TYPE.PARAGRAPH_WITH_TITLE,
    ];

    const render = <div id={id}>{renderBlock(block)}</div>;
    const optimisedRender = (
      <div id={id} ref={ref}>
        {inView && renderBlock(block)}
      </div>
    );

    return excludedBlockTypes.includes(block.type) || optimise === false ? render : optimisedRender;
  };

  return (
    <Container
      hasSpacingTop={hasSpacingTop}
      hasSpacingBottom={hasSpacingBottom}
      data-testid="content-body"
      backgroundColor={backgroundColor}
      textColor={textColor}
    >
      {children}
      {body.map((block, index) => (
        <BlockElement id={block.id} key={index} block={block} />
      ))}
    </Container>
  );
};
