import styled, { css } from 'styled-components';

import { media } from '@theme/media';
import { OFFSET } from '@theme/helpers';
import { CONTAINER_PADDING, CONTAINER_WIDTH, ASSET_PLACEMENT } from '@components/blocks/blocks.constants';
import { FONT_SIZE, typography } from '@theme/font';

export const Caption = styled.figcaption`
  ${typography({ size: FONT_SIZE.XS })};
`;

export const Container = styled.div<{ placement: ASSET_PLACEMENT }>`
  position: relative;
  overflow: visible;
  margin: 0 auto;

  ${({ placement }) =>
    placement === ASSET_PLACEMENT.WIDE
      ? css`
          margin: ${OFFSET.L} 0 ${OFFSET.M};

          ${Caption} {
            margin: ${OFFSET.XS} ${CONTAINER_PADDING}px 0;
          }

          ${media.tablet} {
            margin: ${OFFSET.L} ${CONTAINER_PADDING * 1.5}px ${OFFSET.M};

            ${Caption} {
              margin: ${OFFSET.XS} 0 0;
            }
          }
        `
      : css`
          max-width: 100%;

          ${Caption} {
            margin: ${OFFSET.XS} ${CONTAINER_PADDING}px 0;
          }

          ${media.tablet} {
            max-width: ${CONTAINER_WIDTH - 2 * CONTAINER_PADDING}px;

            ${Caption} {
              margin: ${OFFSET.XS} 0 0;
            }
          }
        `}
`;

export const ImageContainer = styled.figure`
  position: relative;
  margin: 0;
`;

export const Image = styled.img`
  width: 100%;
`;

export const Overlay = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
`;
